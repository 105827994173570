import { createSelector } from '@reduxjs/toolkit'

export const appStore = (state: any) => state.app

export const selectTaskGraphs = createSelector(appStore, (app) => app.graphs)
export const selectCurrentGraph = createSelector(
  appStore,
  (app) => app.currentGraph,
)

export const selectNodesEdgesToSave = createSelector(
  appStore,
  (app) => app.tosave,
)

export const selectGraphNodes = createSelector(appStore, (app) => app.nodes)

export const selectGraphEdges = createSelector(appStore, (app) => app.edges)
