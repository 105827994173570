import React, { useCallback, useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from '../components/sidebar'
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  updateEdge,
} from 'reactflow'
import 'reactflow/dist/style.css'
import {
  getGraphData,
  getTaskGraphs,
  setGraphDataToSave,
} from '../reducers/app'
import { selectCurrentGraph } from '../selectors/app'

const initialNodes = [
  {
    id: '1abc',
    type: 'input',
    data: { label: 'ON_IDLE' },
    position: { x: 250, y: 0 },
  },
]

const initialEdges = [
  { id: 'e1', source: '1abc', target: '2abc' },
]

function Graph() {
  const dispatch: any = useDispatch()

  const edgeUpdateSuccessful = useRef(true)
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  const currentGraph = useSelector(selectCurrentGraph)

  const onConnect = useCallback(
    (params: any) => {
      setEdges((eds) => {
        const updated = addEdge(params, eds)
        return updated
      })
      console.log('on connect')
      dispatch(setGraphDataToSave({ nodes, edges }))
    },
    [setEdges],
  )

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false
    console.log('on onEdgeUpdateStart')
  }, [])

  const onEdgeUpdate = useCallback((oldEdge: any, newConnection: any) => {
    edgeUpdateSuccessful.current = true
    setEdges((els) => {
      const updated = updateEdge(oldEdge, newConnection, els)
      return updated
    })
    dispatch(setGraphDataToSave({ nodes, edges }))
    console.log('on onEdgeUpdate')
  }, [])

  const onEdgeUpdateEnd = useCallback((_: any, edge: any) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => {
        const updated = eds.filter((e) => e.id !== edge.id)
        return updated
      })
      dispatch(setGraphDataToSave({ nodes, edges }))
    }
    edgeUpdateSuccessful.current = true
    console.log('on onEdgeUpdateEnd')
  }, [])

  useEffect(() => {
    dispatch(getGraphData({ graphId: currentGraph.id }))
      .unwrap()
      .then((data: any) => {
        setNodes(data.nodes)
        setEdges(data.edges)
      })
  }, [currentGraph.id])

  return (
    <div className="graph-canvas">
      <Sidebar data={{ nodes, edges, graphId: currentGraph.id }} />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onConnect={onConnect}
        fitView
        attributionPosition="top-right"
      />        
    </div>
  )
}

export default Graph
