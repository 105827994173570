import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useNavigate, Link } from 'react-router-dom'
import Header from '../header'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../reducers/user'
import { getGraphData, getTaskGraphs, setCurrentGraph } from '../../reducers/app'
import { selectCurrentGraph } from '../../selectors/app'

export function MainLayout() {
  const [loading, setLoading] = useState(true)
  const dispatch: any = useDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const searchParams = new URLSearchParams(window.location.search);
  const view = searchParams.get('view');

  useEffect(() => {
    if (token) {
      dispatch(getUser())
        .unwrap()
        .then(() => {
          dispatch(getTaskGraphs({}))
          .unwrap()
          .then((response: any) => {
            let currentGraph = response[0]
            if (view) {
              const graph = response.find((g: any) => g.name === view)
              if (graph) {
                currentGraph = graph
              }
            }
            dispatch(setCurrentGraph(currentGraph))
            setLoading(false)
          })
        })
        .catch(() => {
          navigate('/')
        })
    } else {
      navigate('/')
    }
  }, [])
  if (loading) return <div>Loading...</div>
  return (
    <main>
      <Header />
      <Outlet />
    </main>
  )
}
export default MainLayout
