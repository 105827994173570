import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { LOGIN, USER_AUTH } from '../constants/api'

export const login = createAsyncThunk(
  'app/login',
  async (payload: any, thunkAPI) => {
    const data = {
      email: payload.phone,
      password: payload.password,
    }
    const response = await axios.post(LOGIN, data)
    return response.data
  },
)

export const getUser = createAsyncThunk(
  'app/getUser',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    const response = await axios.post(USER_AUTH)
    return response.data.data
  },
)

export const initialState: any = {
  loading: true,
  authed: false,
  token: null,
  phone: null,
  firstname: null,
  userid: null,
}
export const userStore = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleLoading: (state, action) => {
      const { payload } = action
      state.loading = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.authed = false
    })
    builder.addCase(login.fulfilled, (state, action) => {
      const { payload } = action
      state.authed = true
      localStorage.setItem('token', payload.access_token)
    })
    builder.addCase(login.rejected, (state, action) => {
      state.authed = false
      localStorage.removeItem('token')
    })

    builder.addCase(getUser.pending, (state, action) => {})
    builder.addCase(getUser.fulfilled, (state, action) => {
      const { payload } = action
      state.phone = payload.phone
      state.firstname = payload.firstname
      state.userid = payload.user_id
      state.authed = true
    })
    builder.addCase(getUser.rejected, (state, action) => {
      state.authed = false
      localStorage.removeItem('token')
    })
  },
})

export const { toggleLoading } = userStore.actions

export default userStore.reducer
