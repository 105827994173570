import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GRAPH, GRAPH_UPDATE, TASK_GRAPHS } from '../constants/api'

export const getTaskGraphs = createAsyncThunk(
  'graphs/getTaskGraphs',
  async (payload: any, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    const response = await axios.get(TASK_GRAPHS)
    return response.data.data
  },
)

export const getGraphData = createAsyncThunk(
  'graphs/getGraphData',
  async (payload: any, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    const { graphId } = payload
    const response = await axios.get(`${GRAPH}${graphId}/`)
    return response.data
  },
)

export const updateGraphData = createAsyncThunk(
  'graphs/updateGraphData',
  async (payload: any, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI
    const response = await axios.post(
      `${GRAPH_UPDATE}${payload.graphId}/`,
      payload,
    )
    return response.data
  },
)

export const initialState = {
  loading: true,
  currentGraph: null,
  graphs: [],
  nodes: [],
  edges: [],
  tosave: {
    nodes: null,
    edges: null,
  },
}
export const appStore = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleLoading: (state, action) => {
      const { payload } = action
      state.loading = payload
    },
    setCurrentGraph: (state, action) => {
      const { payload } = action
      state.currentGraph = payload
    },
    setGraphDataToSave: (state, action) => {
      const { payload } = action
      state.tosave = {
        nodes: payload.nodes,
        edges: payload.edges,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTaskGraphs.pending, (state, action) => {})
    builder.addCase(getTaskGraphs.fulfilled, (state, action) => {
      const { payload } = action
      state.graphs = payload
    })
    builder.addCase(getTaskGraphs.rejected, (state, action) => {})

    builder.addCase(getGraphData.pending, (state, action) => {})
    builder.addCase(getGraphData.fulfilled, (state, action) => {
      const { payload } = action
      state.nodes = payload.nodes
      state.edges = payload.edges
    })
    builder.addCase(getGraphData.rejected, (state, action) => {})

    builder.addCase(updateGraphData.pending, (state, action) => {})
    builder.addCase(updateGraphData.fulfilled, (state, action) => {
      const { payload } = action
      state.nodes = payload.nodes
      state.edges = payload.edges
    })
    builder.addCase(updateGraphData.rejected, (state, action) => {})
  },
})

export const { toggleLoading, setCurrentGraph, setGraphDataToSave } =
  appStore.actions

export default appStore.reducer
