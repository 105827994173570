import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { object, string, bool, array, number, lazy } from 'yup'
import { login } from '../reducers/user'

export const LoginValidation = object({
  phone: string().required('Phone number is required'),
  password: string().required('Password is required'),
})

function Login() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const dispatch: any = useDispatch()
  const navigate = useNavigate()
  const onSubmit = (values: any) => {
    setLoading(true)
    setError(false)
    const { phone, password } = values
    dispatch(login({ phone, password }))
      .unwrap()
      .then(() => {
        setLoading(false)
        navigate('/graphs?view=dtc')
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }
  return (
    <div className="login border border-gray-200 rounded-md p-9">
      <h6 className="text-2xl font-bold text-center mb-4">
        AKKO - Task Composer
      </h6>
      <div className="">
        <div className="">
          <Formik
            initialValues={{
              phone: '',
              password: '',
            }}
            validationSchema={LoginValidation}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form className="space-y-4">
                <div className="">
                  <label className="form-label">Phone</label>
                  <Field
                    name="phone"
                    className="form-control"
                    disabled={loading}
                  />
                  <ErrorMessage
                    component="div"
                    className="form-error"
                    name="phone"
                  />
                </div>
                <div className="">
                  <label className="form-label">Password</label>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    disabled={loading}
                  />
                  <ErrorMessage
                    component="div"
                    className="form-error"
                    name="password"
                  />
                </div>
                <div className="">
                  <button type="submit" className="btn" disabled={loading}>
                    {loading ? (
                      <span className="flex items-center justify-center">
                        <Cog6ToothIcon className="animate-spin w-4 h-4 mr-2" />{' '}
                        Login...
                      </span>
                    ) : (
                      'Login'
                    )}
                  </button>
                </div>
                {error && (
                  <div className="form-error">Error on authentification</div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Login
