import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  const token = localStorage.getItem('token')
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold mb-4">Welcome to Task Composer</h1>
      { token ? (
        <Link to="/graphs?view=dtc" className="btn">View Graphs</Link>
      ) : (
        <Link to="/login" className="btn">Login</Link>
      )}
    </div>
  )
}

export default Home
