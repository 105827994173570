import React from 'react'
import { Link } from 'react-router-dom'

export function Header() {
  return (
    <div className="py-4 px-4 shadow bg-slate-50">
      <div className="flex justify-between items-center">
        <Link to="/" className="text-xl font-bold">
          AKKO
        </Link>
        <div className="flex items-center gap-4">
          <Link to="/graph" className="text-slate-500">
            Graphs
          </Link>
          <button type="button" onClick={() => {
            localStorage.removeItem('token')
            window.location.href = '/'
          }} className="text-slate-500">
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}
export default Header
