import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { selectCurrentGraph, selectTaskGraphs } from '../../selectors/app'
import { setCurrentGraph, updateGraphData } from '../../reducers/app'

export function Sidebar({ data }: any) {
  const [loading, setLoading] = useState(false)
  const taskGraphs = useSelector(selectTaskGraphs)
  const [selectedGraph, setSelectedGraph] = useState(
    useSelector(selectCurrentGraph),
  )

  const navigate = useNavigate()
  const dispatch: any = useDispatch()

  const onChangeTaskGraph = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const graphName = selectedOption.text;
    const graph = taskGraphs.find((t: any) => t.name === graphName)
    if (graph) {
      dispatch(setCurrentGraph(graph))
      navigate('/graphs?view=' + graphName)
    }
  }

  const save = () => {
    setLoading(true)
    dispatch(updateGraphData({
      nodes: data.nodes,
      edges: data.edges,
      graphId: data.graphId,
    }))
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <div className="absolute top-20 left-5 w-72 bg-slate-50 shadow-md p-6 z-10">
      <div className="">
        <div className="">
          <div>
            <div className="mb-3">
              <label className="form-label">Select Graph</label>
              <select
                className="form-control"
                onChange={onChangeTaskGraph}
                defaultValue={selectedGraph.id}
              >
                {taskGraphs.map((t: any) => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <button className="btn" onClick={save}>
                {loading ? (
                  <span className="flex items-center justify-center">
                    <Cog6ToothIcon className="animate-spin w-4 h-4 mr-2" />{' '}
                    Saving Changes...
                  </span>
                ) : (
                  'Save Changes'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Sidebar
