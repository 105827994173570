import React from 'react'
import MainLayout from './components/layouts/main'
import Graph from './pages/graph'
import Login from './pages/login'
import Home from './pages/home'
import { Routes, Route } from 'react-router-dom'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/graphs" element={<MainLayout />}>
        <Route index element={<Graph />} />
      </Route>
    </Routes>
  )
}

export default App
