import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from './reducers'
import App from './App'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'
import './scss/index.css'

console.log('v1.1.0')
axios.interceptors.request.use(
  (config: any) => {
    const updatedConfig = { ...config }
    const token = localStorage.getItem('token')
    return {
      ...updatedConfig,
      headers: {
        ...updatedConfig.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  },
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
